import { Injectable } from '@angular/core';
import { ProductLaCascada } from '../classes/product-la-cascada';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LaCascadaProductService {

  // private _url: string = 'https://lacascadacrmtesting.solunes.site/api';
  // private _url: string = 'https://lacascadacrm.test.solunes.com/api';
  // url dev
  // private _url: string = 'https://dev.crm.operaciones.com.bo/api';
  // url test
  // private _url: string = 'https://crm.operaciones.com.bo/api';
  // url master
  private _url: string = 'https://lpzcrm.operaciones.com.bo/api';


  private _auth_token: string = localStorage.getItem('LaCascadaToken') || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jYXNjYWRhLWNybS50ZXN0XC9hcGlcL2xvZ2luVXNlckNsaWVudCIsImlhdCI6MTY3ODIxMDM5NywiZXhwIjoxNjc4MjEzOTk3LCJuYmYiOjE2NzgyMTAzOTcsImp0aSI6IldXQWRCWjVVUmhMQUFwdUUiLCJzdWIiOjEsInBydiI6ImRjNWM2MmMyODdkOTcyZTE1MjdmOWVkYzQwZDJjMzMxMThjMjljNDQifQ.Sy2bvvod_esOV-ffjElJXy51gHbualazN6SFZtNQllA';
  private _userInformation: any = JSON.parse(localStorage.getItem('LaCascadaUser')) || {};
  private _addresses: any[] = JSON.parse(localStorage.getItem('LaCascadaAddresses')) || [];
  private _paymentMethods: any[];
  private _orderHistory: any[] = [];
  private _ordersHistoryTotal: any = {};
  private _targetOrder: any = {};
  private _storageUnits: any = [];
  public lastOrder: any = JSON.parse(localStorage.getItem('LaCascadaLastOrder')) || [];
  public favoriteAddress: number = Number(localStorage.getItem('LaCascadaFavAdd')) || 0;
  public favorites: any[] = JSON.parse(localStorage.getItem('LaCascadaFavs')) || [];
  public querySearch: string = '';
  public hideWidget: boolean = false;
  public notifications: any = [];
  public notifications_to_show: any = [];
  public userVerification: any;
  public flagVerificationCode: any = false;
  public flagError: boolean = false;
  public email_error: string;
  public token_error: string;
  public product_selected_is_favorite: boolean = false;
  public product_selected: any = {
    storage_item_units: [],
  };
  public emailCodeModal: string;
  public loginBySocial: boolean = JSON.parse(localStorage.getItem('social')) || false; 

  private _cities: any[] = [];
  private _brands: any[] = [];
  private _orders: any = {};
  private _aboutUsInformation: any = {};
  private _termsInformation: any = {};
  private _banners: any[] = [];
  private _categories: any[] = [];
  private _productXcategory: any[] = [];
  private _productXcategorySlider: any[] = [];
  private _suggestedProducts: any[] = [];
  private _faqs: any[] = [];
  private _categorySeparated : any = [[],[],[],[],[],[],[],[],[],[],[],[]];
  private _categorySeparated2 :any = [];
  public latLong: number[] = [];
  public searchProductResults: any[] = [];
  public searchProductResultsBrands: any[] = [];
  public searchProductResultsStorage: any[] = [];
  public searchMerge: any[] = [];
  public searchFlag: any[] = [false, ''];
  public flagTitleLogin: boolean = false;
  public searchFlagProductsMerged: boolean = false;
  public showOriginal: boolean = true;
  public actualBrand: number = 0;
  public actualBrandName: string = '';
  public filtersBrands: boolean [] = [];
  public filterStorageUnits: boolean [] = [];
  public showBanner: boolean = false;
  public search: boolean = false;
  public httpLoginResult: string = '';
  public guestFlag: string = localStorage.getItem('guestFlag') || '1';
  public shopFlow: string =  localStorage.getItem('shopFlow') || '0';
  public orderDelayedUrl: string =  localStorage.getItem('orderDelayedUrl') || '';

  public _shoppingCart: any = JSON.parse(localStorage.getItem('LaCascadaCart')) || [];
  public citySelected: string[] = JSON.parse(localStorage.getItem('selectedCity')) || ['1', 'assets/la-cascada/icons/lapaz.svg'];
  public categorySelected: any = localStorage.getItem('selectedCategory') || ['1'];
  public categorySelectedName: string = localStorage.getItem('categorySelectedName') || '' ;
  public categorySelectedNameOnly: string = localStorage.getItem('categorySelectedNameOnly') || '' ;
  public actualStep = 1;
  public loader = document.getElementById('loader-full');
  public loaderCart;
  public flagHeaderFilter: boolean[] = [false, false];
  public promotionalImage: string = '';

  public refreshMarker$ = new BehaviorSubject<number[]>([]);
  // public changeCategory$ = new BehaviorSubject<string>('');
  public updateTotalCart$ = new BehaviorSubject<boolean>(false);
  public clearSearchQuery$ = new BehaviorSubject<boolean>(false);
  public updateFav$ = new BehaviorSubject<boolean>(false);

  public autentificationFields: any;
  constructor( private http: HttpClient, private toastrService: ToastrService, private router: Router) {
    if(this.guestFlag == '1'){
      this.postGuestUser();
    }
  }

  get brands(): any[] {
    return [...this._brands];
  }

  get orders(): any {
    return {...this._orders};
  }

  get aboutUsInformation(): any {
    return {...this._aboutUsInformation};
  }

  get termsInformation(): any {
    return {...this._termsInformation};
  }

  get banners(): any[] {
    return [...this._banners];
  }

  get cities(): any[] {
    return [...this._cities];
  }

  get categories(): any[] {
    return [...this._categories];
  }

  get productXcategory() {
    return [...this._productXcategory];
  }

  get productXcategorySlider() {
    return [...this._productXcategorySlider];
  }

  get suggestedProducts() {
    return [...this._suggestedProducts];
  }

  get faqs(){
    return [...this._faqs];
  }

  get categorySeparated() {
    return [...this._categorySeparated];
  }

  get categorySeparated2() {
    return [...this._categorySeparated2];
  }

  get userInformation() {
    return {...this._userInformation};
  }

  get shoppingCart() {
    return this._shoppingCart;
  }

  get addresses() {
    return [...this._addresses];
  }

  get paymentMethods() {
    return this._paymentMethods;
  }

  get ordersHistory() {
    return this._orderHistory;
  }

  get ordersHistoryTotal() {
    return {...this._ordersHistoryTotal};
  }

  get targetOrder() {
    return {...this._targetOrder};
  }

  get storageUnits() {
    return [...this._storageUnits];
  }

  getActualUrl(){
    return this._url;
  }

  validateForm(form: any){
    for(let i in form.controls)
      form.controls[i].markAsTouched();
  }

  hideShoppingWidget(){
    var blackout = document.getElementById('blackout-widget');
    if(this.hideWidget == true){
      this.hideWidget = false;
      blackout.classList.remove('visual-active');
      setTimeout(() => {
        blackout.classList.remove('active');
      }, 200);
    }else{
      this.hideWidget = true;
      blackout.classList.add('active');
      blackout.classList.add('visual-active');
    }
  }
  searchToggle(){
    setTimeout(() => {
      this.getCategoriesToSubscribe(this.citySelected[0])
      .subscribe( (resp: any)=>{
        var allId: string = '';
        resp.items.data.forEach(category => {
          if(category.fldName.toLowerCase() == 'todos'){
            allId = category.pkyFamily
          }
        });
        // this.changeCategory$.next(allId);
        this.router.navigate([`/home/products/${allId}`])
        setTimeout(() => {
          this.search = !this.search;
        }, 500);
      });
    }, 10);
  }

  searchToggleOff(){
    this.search = false;
    this.clearSearchQuery$.next(true);
  }

  searchProduct(value: string){
    this.searchProductResults = [];
    this.searchMerge = [];
    this.flagHeaderFilter[1] = false;
      this.productXcategory.forEach(element => {
        if(element.fldName.toLowerCase().includes(value.toLowerCase()) ){
         this.searchProductResults.push(element);
        }
      });

    for (let index = 0; index < this.filterStorageUnits.length; index++) {
      this.filterStorageUnits[index] = false;
    }

    if(this.searchProductResults.length != 0){
      this.searchFlag[0] = false;
      this.searchFlagProductsMerged = false;
    }else{
      this.searchFlag[0] = true;
      this.searchFlag[1] = value;
    }

    if(value = ''){
      this.showOriginal = true;
    }else{
      this.showOriginal = false;
    }
  }

  searchStorageUnits(value: number, description: string){

    this.showBanner = false;
    this.searchProductResults = [];
    this.searchFlag[0] = false;
    this.searchToggleOff();

    this.flagHeaderFilter[1] = true;
    this.flagHeaderFilter[0] = false;
    for (let index = 0; index < this.filterStorageUnits.length; index++) {
      this.filterStorageUnits[index] = false;
    }

    this.filterStorageUnits[value] = true;
    this.searchProductResultsStorage = [];

    this._productXcategory.forEach(element => {
      for (let index = 0; index < element.storage_item_units.length; index++) {
        if (value == element.storage_item_units[index].codStorageUnit) {
          this.searchProductResultsStorage.push(element);
        }
      }
    });


    // if(this.searchProductResultsBrands.length != 0){
    //   this.searchMerge = [];
    //   this.searchProductResultsBrands.forEach(element => {
    //     this.searchProductResultsStorage.forEach(element2 => {

    //       if (element.pkyItem == element2.pkyItem) {
    //         this.searchMerge.push(element2);
    //       }
    //     });
    //   });
    // }else{
    //   this.searchMerge = this.searchProductResultsStorage;
    // }

    this.searchMerge = this.searchProductResultsStorage;

    this.searchFlagProductsMerged = true;

    // clear brand selected
    for (let index = 0; index < this.filtersBrands.length; index++) {
      this.filtersBrands[index] = false;
    }


    if(this.searchMerge.length != 0){
      this.searchFlagProductsMerged = false;
      this.showOriginal = false;

      if(this.actualBrand >= 1 && this.actualBrand <= 7){
        // console.log(this.actualBrand);
        this.showBanner = true;
      }
    }

    this.searchFlag[1] = description;
  }

  changeBrand( value: number ){

    this.searchProductResults = [];
    this.searchFlag[0] = false;
    this.searchToggleOff();

    this.flagHeaderFilter[0] = true;
    // var newName = name.toLowerCase();

    // this.categorySelectedName = newName.charAt(0).toUpperCase() + newName.slice(1);

    this.actualBrandName = '';
    this.brands.forEach(brand => {
      if(brand.pkyItemBrand == value){
        this.actualBrandName = brand.imageBanner;
      }
    });

    for (let index = 0; index < this.filtersBrands.length; index++) {
     this.filtersBrands[index] = false;
    }
    this.filtersBrands[value] = true;

    localStorage.setItem('categorySelectedName', this.categorySelectedName);
    // this.getProductXCityXBrand( this.citySelected[0] , value.toString());

    this.searchProductResultsBrands = [];

    this._productXcategory.forEach(element => {
      if (value.toString() == element.storage_brand.pkyItemBrand) {
        this.searchProductResultsBrands.push(element);
      }
    });

    // if(this.searchProductResultsStorage.length != 0){
    //   this.searchMerge = [];
    //   this.searchProductResultsStorage.forEach(element => {
    //     this.searchProductResultsBrands.forEach(element2 => {

    //       if (element2.pkyItem == element.pkyItem) {
    //         this.searchMerge.push(element2);
    //       }
    //     });
    //   });
    // }else{
    //   this.searchMerge = this.searchProductResultsBrands;
    // }
    this.searchProductResultsStorage = [];
    this.searchMerge = this.searchProductResultsBrands;

    this.searchFlagProductsMerged = false;
    this.flagHeaderFilter[1] = false;

    // if(this.searchMerge.length != 0){
    //   this.searchFlagProductsMerged = false;
    // }
    this.showOriginal = false;
    this.showBanner = true;

    for (let index = 0; index < this.filterStorageUnits.length; index++) {
      this.filterStorageUnits[index] = false;
    }
  }

  objetEmpty(obj: any){
    let response: boolean = false;

    if(obj == undefined){
      obj = [];
    }

    if(Object.keys(obj).length == 0){
      response = true;
    }
    return response;
  }

  // get request

  getPromotionalImageToSubscribe(){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('type', `OFFER`);

    return this.http.get(this._url + '/model/InvSuggestion', { params })
  }

  getSingleProductToSubscribe(id: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codUser', this.userInformation.pkyBill)
    .set('pkyItem', `${id}`);

    return this.http.get(this._url + '/model/InvItem', { params });
  }

  getTermsInformation(){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('type', `term`);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvAboutUs', { params })
      .subscribe( ( resp:any )=>{

        this._termsInformation = resp.items.data[0];
        this.loader.classList.remove('show');
    })
  }

  setUserDataByToken(token :string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('token', token);

    this.loader.classList.add('show');

    this.http.get(this._url + '/userGet', { params })
      .subscribe( ( resp:any )=>{
        if(resp.status){
          this.loginBySocial = true;
          localStorage.setItem('social', JSON.stringify(this.loginBySocial));
          this.guestFlag = '0';
          this._userInformation = resp.item;
          localStorage.setItem('LaCascadaToken', token);
          this.toastrService.success(`Bienvenido de nuevo ${this.userInformation.firstName}`, '', {timeOut: 5000});
          this._auth_token = token;

          if(this.shoppingCart != undefined){
            if(this.shoppingCart.length != 0){
              this.router.navigate(['/home/shopping-cart-details']);
            }else{
              this.router.navigate(['/home/sign-in']);
            }
          }else{
            this.router.navigate(['/home/sign-in']);
          }

          localStorage.setItem('guestFlag', this.guestFlag);

          // guest favorites placement
          this.favorites.forEach(element => {
            var addParams = {
              codItem: element.pkyItem,
              codUser: this.userInformation.pkyBill
            }
            this.postAddFav(addParams)
            .subscribe(( resp: any )=>{
              if(resp.status)
              {
                // this.favoriteSelected[0] = true;
                // this.favoriteSelected[1] = resp.item.pkyItemFavourite;
                // localStorage.setItem( 'selectedFavorite', JSON.stringify(this.favoriteSelected));
                this.getFavorites();
              }else{
                // this.toastrService.error(`${resp.mensaje}`, '', {timeOut: 5000} );
              }
            });
          });
          this.getFavorites();
          // this.favoriteSelected[0] = false;
          // this.favoriteSelected[1] = 0;
          // localStorage.setItem( 'selectedFavorite', JSON.stringify(this.favoriteSelected));

          // guest shoppingCart placement
          if(this.shoppingCart?.storage_cart_detail?.length != undefined){
            var oldShoppingCart = this._shoppingCart;

            this.toastrService.info('<div class="toastr-buttons">Prueba buscando en otra categoría o marca.</div>', '¿Deseas agregar los productos de tu anterior carrito a tu compra actual?', {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false } );
            setTimeout(() => {
              var toastrButtons = document.querySelector('.toastr-buttons');
              toastrButtons.innerHTML = '<div class="d-flex gap-2 flex-wrap mt-2"><button class="btn btn-xsm btn-white add-option-toast">Agregar</button> <button class="btn btn-xsm btn-white hide-option-toast">No agregar</button></div>';

              var hideOptionToast = document.querySelector('.hide-option-toast');
              var addOptionToast = document.querySelector('.add-option-toast');
              hideOptionToast.addEventListener("click", (e: any)=>{
                var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
                setTimeout(() => {
                  toastrElement.style.display = 'none';
                }, 300);
                toastrElement.style.opacity = '0';

              });

              addOptionToast.addEventListener("click", (e: any)=>{
                var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
                setTimeout(() => {
                  toastrElement.style.display = 'none';
                }, 300);
                toastrElement.style.opacity = '0';

                // this.getShoppingCartToSubscribe()
                //   .subscribe((newCart: any)=>{
                //     if(newCart.items.data[0].storage_cart_detail.length !=0){
                //       this.toastrService.info(`Productos agregados correctamente a la compra pendiente`, '', {timeOut: 5000, extendedTimeOut: 0,} );
                //     }else{
                //       this.toastrService.info(`Productos nuevos agregados al carrito`, '', {timeOut: 5000} );
                //     }
                //   })

                // adding products
                oldShoppingCart.storage_cart_detail?.forEach(element => {
                  this.postAddProductShoppingCartToSubscribe({ codItem: element.codItem, fldQuantity: element.fldQuantity })
                    .subscribe(( resp: any )=>{
                      if(resp.status){
                        this.getShoppingCart();
                      }else{
                        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
                      }
                    });
                  });
                });
            }, 100);


          }
          this.getShoppingCart();


          // guest directions placement
          var old_Addresses = this._addresses;
          this.getAddressesToSubscribe()
            .subscribe((new_addresses: any)=>{
              if(new_addresses.items.data.length != 0){
                this._addresses = new_addresses.items.data;
              }
              // guest user
              this.getAddresses();
            })

          // get notifications
          this.getNotifications(this.userInformation.pkyBill)
          .subscribe( ( resp:any )=>{
            this.notifications = resp.items.data;

            this.notifications_to_show = [];
            this.notifications.forEach(element => {
              if(element.fldIsSent == false){
                this.notifications_to_show.push(element);
                this.toastrService.info(`${element.fldName}`, `${element.fldMessage}`, {timeOut: 5000} );
              }
            });
          });
          
          localStorage.setItem('LaCascadaUser', JSON.stringify(this._userInformation));
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }

        // if(resp.active == false){
        //   this.autentificationFields = undefined;
        //   this.userVerification = {
        //     pkyBill : resp.pkyBill,
        //     password : resp.password,
        //     code : resp.confirmationCode,
        //   };
        //   this.router.navigate([`/home/confirmation-code/${resp.pkyBill}`]);
        // }


        this.loader.classList.remove('show');
    })
  }

  getAboutUsInformation(){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    // .set('type', `term`);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvAboutUs', { params })
      .subscribe( ( resp:any )=>{
        this._aboutUsInformation = resp.items.data[0];

        this.loader.classList.remove('show');
    })
  }

  getBrands(codFamily: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codFamily', codFamily);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvItemBrand', { params })
      .subscribe( ( resp:any )=>{

        this._brands = resp.items.data;
        // console.log(this._categories);
        this.loader.classList.remove('show');
    })
  }

  getSupportAddressToSubscribe(codcity: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('type', 'address')
    .set('codCity', codcity);

    return this.http.get(this._url + '/model/InvTextSupport', { params });
  }

  getBanners(codcity: string){

    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('type', 'BANNER')
    .set('codCity', codcity);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvSuggestion', { params })
      .subscribe( ( resp:any )=>{

        this._banners = resp.items.data;
        // console.log(resp.items.data);
        this.loader.classList.remove('show');
    })
  }

  getUser(pkyBill: number){    
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('pkyBill', pkyBill);

    this.http.get(this._url + '/model/UserBill', { params })
      .subscribe( ( resp:any )=>{
        this._userInformation = resp.items.data[0];

        localStorage.setItem('LaCascadaUser', JSON.stringify(resp.items.data[0]));
    })
  }

  getNotifications(codUser: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codUser', codUser);

    // this.loader.classList.add('show');

    return this.http.get(this._url + '/model/UserNotification', { params });
  }

  getCategories(codcity: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codCity', codcity);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvFamily', { params })
      .subscribe( ( resp:any )=>{

        this._categories = resp.items.data;
        this.loader.classList.remove('show');
    })
  }

  getCategoriesToSubscribe(codcity: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codCity', codcity);

    return this.http.get(this._url + '/model/InvFamily', { params })
  }

  getCities( hideLoader?: boolean){

    if(hideLoader == undefined){
      hideLoader = false;
    }

    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`);

    // this.loader.classList.add('show');
    this.http.get(this._url + '/model/InvCity', { params })
      .subscribe( ( resp:any )=>{

        this._cities = resp.items.data;
        if(hideLoader){
          this.loader.classList.remove('show');
        }
    });
  }

  getCitiesToSubscribe(){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`);

    return this.http.get(this._url + '/model/InvCity', { params });
  }

  getSpecificProductXCity( codcity :string, codProduct: number ){
    // this.productSelected = {};
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codCity', codcity);

    this.loader.classList.add('show');

    this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        // this._productXcategory = resp.items;

        resp.items.forEach(element => {
          if (codProduct == element.pkyItem) {
            // this.productSelected = element;
            // localStorage.setItem( 'selectedProduct', JSON.stringify(this.productSelected));
          }
        });
        this.loader.classList.remove('show');
    });
  }

  getProductXCity( codcity :string, query: number ){
    this._productXcategory = [];
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codCity', codcity);

    this.loader.classList.add('show');

    this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        // this._productXcategory = resp.items;
        resp.items.forEach(element => {
          // console.log(element.storage_item_units[0].codStorageUnit, element.storage_item_units[0].txtDescription);
          if (query == element.storage_item_units[0].codStorageUnit) {
            this._productXcategory.push(element);
          }
        });

        this.loader.classList.remove('show');
    });
  }

  getProductXCityXBrand( codcity :string, codbrand: string ){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codBrand', codbrand)
      .set('codCity', codcity);

    this.loader.classList.add('show');
    this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        this._productXcategory = resp.items;

        this.loader.classList.remove('show');
    });
  }

  getFaqsQuestions(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('type', 'help');

    this.loader.classList.add('show');
    this.http.get(this._url + `/model/InvTextSupport`, { params })
      .subscribe( ( resp: any )=>{
        this._faqs = resp.items.data;
        this.loader.classList.remove('show');
    });
  }

  getFavorites(reload?: boolean){
  if(reload == undefined){
    reload = false;
  }
  if (this.guestFlag == '0') {
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

      // this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvItem`, { params })
        .subscribe( ( resp: any )=>{
          this.favorites = [];
          resp.items.forEach(element => {
            if(element.favourite){
              this.favorites.push(element);
            }
          });
          localStorage.setItem('LaCascadaFavs', JSON.stringify(this.favorites));
          // this.loader.classList.remove('show');
          if(reload){
            window.location.reload();
          }
        });
    }
  }

  getShoppingCart(){
    if (this.guestFlag == '0') {
      let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);
      // this.loader.classList.add('show');

      this.http.get(this._url + `/model/SlsCart`, { params })
        .subscribe(( resp: any )=>{
          this._shoppingCart = resp.items.data[0];
          // this.loader.classList.remove('show');
          this.loaderCart?.classList.remove('show');
          var productBrand = this._shoppingCart?.storage_cart_detail[this._shoppingCart.storage_cart_detail.length - 1].storage_item.storage_item_sub_family[0].storage_sub_family.storage_family.pkyFamily;

          if(productBrand != undefined){
            this.getSuggestedProducts( Number(this.citySelected[0]), productBrand);
          }
        })
    }
  }

  getShoppingCartToSubscribe(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);



    return this.http.get(this._url + `/model/SlsCart`, { params })
  }

  getAddNewSiteToSubscribe(){
    return this.http.get(this._url + `/model/InvTextSupport?type=index&description=web`)
  }

  getAddresses(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    // this.loader.classList.add('show');        

      this.http.get(this._url + `/model/UserAddress`, { params })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this._addresses = resp.items.data;
          // guest user
          localStorage.setItem('LaCascadaAddresses', JSON.stringify(this._addresses));
        }else{
          this.toastrService.error(`Hubo un error al obtener las direcciones, por favor recarga la página`, '', {timeOut: 5000} );
        }
          // this.loader.classList.remove('show');
      })
  }

  getAddressesToSubscribe(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

      return this.http.get(this._url + `/model/UserAddress`, { params })
  }

  getPaymentMethods(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    // this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvPaymentMethod`, { params })
      .subscribe(( resp: any )=>{
        this._paymentMethods = resp.items.data;
        // this.loader.classList.remove('show');
    });
  }

  getPaymentMethodsToSubscribe(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    // this.loader.classList.add('show');

      return this.http.get(this._url + `/model/InvPaymentMethod`, { params })
  }

  getOrderHistory(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.loader.classList.add('show');

    this.http.get(this._url + `/postOrders`, { headers })
      .subscribe(( resp: any )=>{
        this._orderHistory = resp.item.pendientes;
        this.loader.classList.remove('show');
    });
  }

  getOrdersHistoryTotal(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.loader.classList.add('show');

    this.http.get(this._url + `/postOrders`, { headers })
      .subscribe(( resp: any )=>{
        this._ordersHistoryTotal = resp.item;
        // console.log(this._ordersHistoryTotal, 'ordersHistoryTotal');

        this.loader.classList.remove('show');
    },(error: any) =>{
      this.loader.classList.remove('show');
      this.router.navigate(['/home/login']);
      this.toastrService.error(`Inicia sesión nuevamente para ver tu historial`, '', {timeOut: 5000} );
    });
  }

  getTargetOrder(query: number){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyOrder', query);

    this.loader.classList.add('show');

      this.http.get(this._url + `/model/SlsOrder`, { params })
      .subscribe(( resp: any )=>{
        this._targetOrder = resp.items;

        this.loader.classList.remove('show');
    });
  }

  getTargetOrderToSubscribe(query: number){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyOrder', query);

      return this.http.get(this._url + `/model/SlsOrder`, { params })
  }

  getOrderConfirmation(query: number){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyOrder', query);

      this.http.get(this._url + `/model/SlsOrder`, { params })
      .subscribe(( resp: any )=>{
        if(resp.status){

          this._orders = resp.items.data[0];

          this.loader.classList.remove('show');
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  getStorageUnits(code: string){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codFamily', code);

    this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvStorageUnit`, { params })
      .subscribe(( resp: any )=>{
        this._storageUnits = resp.items;
        this.loader.classList.remove('show');
    });
  }

  getProductXCityXCategory( codcity :string, codfamily: string, type :string, paramBrand?: number ){

    if(paramBrand == undefined){
      paramBrand = -1;
    }

    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codFamily', codfamily)
      .set('codCity', codcity);

    this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        switch (type) {
          case 'slider':
            this._productXcategorySlider = resp.items;
          break;
          case 'separate':
            this._categorySeparated[Number(codfamily) - 1] = resp.items;
          break;
          default:
            this._productXcategory = resp.items;
          break;
        }

        if(paramBrand != -1){
          this.changeBrand(paramBrand);
          // this.getPromotionalImageToSubscribe()
          // .subscribe((resp: any)=>{
          //   if(resp.items.data.length > 0){
          //     this.promotionalImage = resp.items.data[0].urlImage;
          //   }
          // })  
        }

        setTimeout(() => {
          this.loader.classList.remove('show');
        }, 500);
    });
  }

  getSuggestedProducts( codcity :number, codfamily: number){

    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codCity', codcity)
      .set('codFamily', codfamily);

    // this.loaderCart.classList.add('show');

      this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        this._suggestedProducts = resp.items.reverse();

        // this.loaderCart.classList.remove('show');
    });
  }

  getProductXCityXCategorySeparated( codcity :string ){

    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codCity', codcity);

    this.loader.classList.add('show');

      this.http.get(this._url + `/allCategories`, { params })
      .subscribe( ( resp: any )=>{
        this._categorySeparated2 = resp.data.categories;

        // this._categorySeparated = [[],[],[],[],[],[],[],[],[],[],[],[]];
        // resp.items.forEach(element => {
        //   element.storage_item_sub_family.forEach(element2 => {
        //     console.log(element.storage_brand.codFamily);
        //     this._categorySeparated[element.storage_brand.codFamily - 1].push(element);

        //   });
        // });
        // console.log(this._categorySeparated, 'separated');
        this.loader.classList.remove('show');
    });
  }

  get_GoogleLogin() {
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    return this.http.get( this._url + `/login/google`, { headers })
      .pipe( map((resp:any) => {
        // console.log(resp);

      })
    )
    // fetch('https://lacascadacrmprod.solunes.site/api/login/google', {
    //   headers: {Authorization: `Bearer ${this._auth_token}`},
    // })
    //   .then(response => response.json())
    //   .then(json => console.log(json))
  }

  getGoogleLogin(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`,
    });


    this.http.get( this._url + `/login/google`, { headers })
      .subscribe(( resp: any )=>{
        // console.log(resp, 'resp');
        
          this.httpLoginResult = resp;
      }, (error: any)=>{
        // console.log(error, 'error');
        
        this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  // post requests

  postRegisterNewUser(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    this.http.post(this._url + `/registerUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.toastrService.success(resp.message, '', {timeOut: 5000})
          var verificationData: object = {
            pkyBill: resp.item.user.pkyBill,
            password: resp.password,
            email: resp.item.user.email
          }
          this.autentificationFields = verificationData;

          this.router.navigate([`/home/confirmation-code/${resp.item.user.pkyBill}`]);
        }else{
          this.flagError = false;
          if(resp.mail != undefined){
            this.email_error = resp.mail;
          }
          resp.errors.forEach((error: any) => {
            if(error.includes('documento') && resp.email != undefined){
              if(this.flagError == false){
                this.showToastButtons(error, fields);
                this.flagError = true;
                return;
              }
            }
            if(error.includes('email') && resp.email != undefined){
              if(this.flagError == false){
                this.showToastButtons(error, fields);
                this.flagError = true;
                return;
              }
            }
            this.toastrService.error(`${error}`, '', {timeOut: 5000} );
          });

        }
    });
  }

  showToastButtons(error: any, fields: any){
    this.toastrService.error('<div class="toastr-buttons"></div>', `${error}`, {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false } );

    setTimeout(() => {
      var toastrButtons = document.querySelector('.toastr-buttons');
      toastrButtons.innerHTML = '<div class="d-flex gap-2 flex-wrap mt-2"><button class="btn btn-xsm btn-white redirect-option-toast">Recuperar cuenta</button> <button class="btn btn-xsm btn-white hide-option-toast">Ahora no</button></div>';

      var hideOptionToast = document.querySelector('.hide-option-toast');
      var redirectOptionToast = document.querySelector('.redirect-option-toast');
      hideOptionToast.addEventListener("click", (e: any)=>{
        var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
        setTimeout(() => {
          toastrElement.style.display = 'none';
        }, 300);
        toastrElement.style.opacity = '0';
      });

      redirectOptionToast.addEventListener("click", (e: any)=>{
        var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
        setTimeout(() => {
          toastrElement.style.display = 'none';
        }, 300);
        toastrElement.style.opacity = '0';
        this.postForgotPassToSubscribe({email: this.email_error})
        .subscribe((resp: any)=>{
          if(resp.status){
            this.token_error = resp.token;
            this.router.navigate(['/home/forgot-pass/2']);
          }else{
            this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
          }
        })
      });
    }, 100);
  }

  postVerifyUserLater(code: string, id: string){
    let params = new HttpParams ()
    .set('Authorization', `Bearer ${this._auth_token}`);

    var body = {
      pkyBill : id,
      password : this.userVerification.password,
      code : code,
    }


    this.http.post(this._url + `/registerVerifyUserClient`, body, { params } )
    .subscribe(( resp: any )=>{

      if(resp.status){

        var loginData: object= {
          userName: resp.item.user.email,
          password: this.userVerification.password
        }

        this.postLoginUser(loginData, false);
        this.toastrService.success(`Usuario registrado correctamente`, '', {timeOut: 5000});
      }else{
        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    });
  }

  postReesendCode(pkybill: string){
    let params = new HttpParams ()
    .set('Authorization', `Bearer ${this._auth_token}`);

    this.http.post(this._url + `/registerVerifyUserClientSend`, { pkyBill: pkybill}, { params } )
    .subscribe(( resp: any )=>{
      if(resp.status){
        this.flagVerificationCode = true;
      }else{
        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    });
  }

  postVerifyUser(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);

    this.http.post(this._url + `/registerVerifyUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){

          var loginData: object= {
            userName: resp.item.user.email,
            password: this.autentificationFields.password
          }

          this.postLoginUser(loginData, false);
          this.toastrService.success(`Usuario registrado correctamente`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    });
  }

  postNotificationsReaded(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    this.http.post(this._url + `/model/UserNotification/edit`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          // this.toastrService.success(`Usuario registrado correctamente`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.errors}`, '', {timeOut: 5000} );
        }
    });
  }

  postUpdateProfileUser(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    this.http.post(this._url + `/registerUserImage`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.getUser(this.userInformation.pkyBill)
        }else{
          this.toastrService.error(`${resp.errors}`, '', {timeOut: 5000} );
        }
    }, (error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    })
  }

  postEditUser(fields: any, message?: boolean){

    if(message == undefined){
      message = true;
    }

    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);
    
    if(this.userInformation.email != fields.email){
      this.postUpdateUserEmail({ email: fields.email });
      Swal.fire({
        title: `¿Desea cambiar su correo?`,
        icon: 'question',
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: 'Si, cambiar',
        denyButtonText: 'No',
        preConfirm: () => {
          var emailCodeContainer :any = document.getElementById('email-code-container');
          var emailCodeInput :any = emailCodeContainer.querySelector('input');
          var willClose: boolean = false;
          
          if (emailCodeInput.value != '') {
            willClose = true;
          }else{
            this.validateFormModal(emailCodeContainer);
            emailCodeInput.addEventListener("input", ()=>{
              if(emailCodeInput.value == ''){
                this.validateFormModal(emailCodeContainer);
              }else{
                emailCodeContainer.classList.remove('control-invalid');
                emailCodeContainer.querySelector('p').classList.add('d-none');
              }
            });
          }
          return willClose;
        },
        html: `<div style="font-size: 18px; margin-bottom: 1rem; font-weight: normal; line-height: normal; text-align: center;">${fields.email}</div>
                <div class="input-container" id="email-code-container">
                  <div style="max-width: 240px; margin: auto">
                    <input type="text" placeholder="Ingrese el código enviado a su correo">
                    <p class="d-none">
                        <img src="../../../assets/la-cascada/icons/x-circle.svg" alt="icon">
                        <label>
                            Este campo es necesario
                        </label>
                    </p>
                  </div>
                </div>`,
      }).then((result) => {
        if (result.isConfirmed) {
          var emailCodeContainer :any = document.getElementById('email-code-container');
          var emailCodeInput :any = emailCodeContainer.querySelector('input');
          
          this.emailCodeModal = emailCodeInput.value;
          var fields2 = {
            code: this.emailCodeModal,
            email: fields.email
          }
          this.postConfirmEmail(fields2);
          this.finishPostUpdateUser(fields, params, message);
        }
      })
    }else{
      this.finishPostUpdateUser(fields, params, message);
    }
  }

  finishPostUpdateUser(fields: any, params: any, message: any){
    this.http.post(this._url + `/updateUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          localStorage.setItem('LaCascadaUser', JSON.stringify(resp.item.user));
          this._userInformation = resp.item.user;
          // if(this.shopFlow == '1'){
          //   this.router.navigate(['/home/shopping-cart-details']);
          //   this.shopFlow = '0';
          //   localStorage.setItem('shopFlow', this.shopFlow);
          // }else{
          //   this.router.navigate(['/home/featured']);
          // }
          if(message){
            
            this.router.navigate(['/home/featured']);
            this.toastrService.success(resp.message, '', {timeOut: 5000});
          }
        }else{
          this.toastrService.error(`${resp.errors}`, '', {timeOut: 5000} );
        }
    }, (error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    }
    );
  }

  validateFormModal(field: any){
    field.classList.add('control-invalid');
    field.querySelector('p').classList.remove('d-none');
  }

  postLoginUser(fields: any, message?: boolean){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    if(message == undefined){
      message = true;
    }

    this.http.post(this._url + `/loginUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){

          if(message){
            this.toastrService.success(`Bienvenido de nuevo ${resp.item.user.firstName}`, '', {timeOut: 5000});
          }
          this._auth_token = resp.token;
          localStorage.setItem('LaCascadaToken', resp.token);
          localStorage.setItem('LaCascadaUser', JSON.stringify(resp.item.user));
          this._userInformation = resp.item.user;

          if(this.shoppingCart.length != 0){
            this.router.navigate(['/home/shopping-cart-details']);
          }else{
            this.router.navigate(['/home/location']);
          }

          this.guestFlag = '0';
          localStorage.setItem('guestFlag', this.guestFlag);

          // guest favorites placement
          this.favorites.forEach(element => {
            var addParams = {
              codItem: element.pkyItem,
              codUser: this.userInformation.pkyBill
            }
            this.postAddFav(addParams)
            .subscribe(( resp: any )=>{
              if(resp.status)
              {
                // this.favoriteSelected[0] = true;
                // this.favoriteSelected[1] = resp.item.pkyItemFavourite;
                // localStorage.setItem( 'selectedFavorite', JSON.stringify(this.favoriteSelected));
                this.getFavorites();
              }else{
                // this.toastrService.error(`${resp.mensaje}`, '', {timeOut: 5000} );
              }
            });
          });
          this.getFavorites();
          // this.favoriteSelected[0] = false;
          // this.favoriteSelected[1] = 0;
          // localStorage.setItem( 'selectedFavorite', JSON.stringify(this.favoriteSelected));

          // guest shoppingCart placement
          if(this.shoppingCart?.storage_cart_detail?.length != undefined){
            var oldShoppingCart = this._shoppingCart;

            this.toastrService.info('<div class="toastr-buttons">Prueba buscando en otra categoría o marca.</div>', '¿Deseas agregar los productos de tu anterior carrito a tu compra actual?', {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false } );
            setTimeout(() => {
              var toastrButtons = document.querySelector('.toastr-buttons');
              toastrButtons.innerHTML = '<div class="d-flex gap-2 flex-wrap mt-2"><button class="btn btn-xsm btn-white add-option-toast">Agregar</button> <button class="btn btn-xsm btn-white hide-option-toast">No agregar</button></div>';

              var hideOptionToast = document.querySelector('.hide-option-toast');
              var addOptionToast = document.querySelector('.add-option-toast');
              hideOptionToast.addEventListener("click", (e: any)=>{
                var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
                setTimeout(() => {
                  toastrElement.style.display = 'none';
                }, 300);
                toastrElement.style.opacity = '0';

              });

              addOptionToast.addEventListener("click", (e: any)=>{
                var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
                setTimeout(() => {
                  toastrElement.style.display = 'none';
                }, 300);
                toastrElement.style.opacity = '0';

                // this.getShoppingCartToSubscribe()
                //   .subscribe((newCart: any)=>{
                //     if(newCart.items.data[0].storage_cart_detail.length !=0){
                //       this.toastrService.info(`Productos agregados correctamente a la compra pendiente`, '', {timeOut: 5000, extendedTimeOut: 0,} );
                //     }else{
                //       this.toastrService.info(`Productos nuevos agregados al carrito`, '', {timeOut: 5000} );
                //     }
                //   })

                // adding products
                oldShoppingCart.storage_cart_detail?.forEach(element => {
                  this.postAddProductShoppingCartToSubscribe({ codItem: element.codItem, fldQuantity: element.fldQuantity })
                    .subscribe(( resp: any )=>{
                      if(resp.status){
                        this.getShoppingCart();
                      }else{
                        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
                      }
                    });
                  });
                });
            }, 100);


          }
          this.getShoppingCart();


          // guest directions placement
          var old_Addresses = this._addresses;
          this.getAddressesToSubscribe()
            .subscribe((new_addresses: any)=>{
              if(new_addresses.items.data.length != 0){
                this._addresses = new_addresses.items.data;
              }
              // guest user

              old_Addresses.forEach(element => {
                var coincidence = false;
                this._addresses.forEach(newElement =>{
                  if(element.alias.localeCompare(newElement.alias, 'en', {sensitivity: 'base'}) == 0 &&
                     element.fldAddress.localeCompare(newElement.fldAddress, 'en', {sensitivity: 'base'}) == 0 &&
                     element.fldDistrict.localeCompare(newElement.fldDistrict, 'en', {sensitivity: 'base'}) == 0){

                      this.toastrService.error(`Dirección existe ( No fue agregada)`, '', {timeOut: 5000} );
                      coincidence = true;
                      return;
                  }
                })
                if( !coincidence ){
                  var direction = {
                    alias: element.alias,
                    fldLatitude: element.fldLatitude,
                    fldLongitude: element.fldLongitude,
                    fldAddress: element.fldAddress,
                    fldDistrict: element.fldDistrict,
                    fldReference: element.fldReference,
                    type: element.type,
                    deliverySchedule: element.deliverySchedule,
                    codUser: this.userInformation.pkyBill,
                    favoriteAddress: true,
                    id: ""
                  }

                  this.postAddNewAddressToSubscribe(direction)
                    .subscribe(( resp: any )=>{
                      if(resp.status){
                        if(direction.favoriteAddress){
                          this.favoriteAddress = resp.item.pkyAddress;
                          localStorage.setItem('LaCascadaFavAdd', resp.item.pkyAddress);
                        }
                      }else{
                        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
                      }
                  });
                }
              });
              this.getAddresses();
            })

          // get notifications
          this.getNotifications(this.userInformation.pkyBill)
          .subscribe( ( resp:any )=>{
            this.notifications = resp.items.data;

            this.notifications_to_show = [];
            this.notifications.forEach(element => {
              if(element.fldIsSent == false){
                this.notifications_to_show.push(element);
                this.toastrService.info(`${element.fldName}`, `${element.fldMessage}`, {timeOut: 5000} );
              }
            });
          });
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }

        if(resp.active == false){
          this.autentificationFields = undefined;
          this.userVerification = {
            pkyBill : resp.pkyBill,
            password : resp.password,
            code : resp.confirmationCode,
          };
          this.router.navigate([`/home/confirmation-code/${resp.pkyBill}`]);
        }
    });
  }

  postForgotPass(fields: any ){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    this.http.post(this._url + `/logRecoverPassword`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){

          this.toastrService.success(`Se envió un código de recuperación tu correo`, '', {timeOut: 5000});
          this.actualStep = 2;
          // this.router.navigate(['/home/login'])
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postConfirmEmail(fields: any ){
    let params = new HttpParams ()
      // .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyBill', `${this.userInformation.pkyBill}`)
    
    this.http.post(this._url + `/confirm-email`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){

          this.toastrService.success(`${resp.message}`, '', {timeOut: 5000});
          this.postLogOut({ userId: this.userInformation.pkyBill });
          // this.router.navigate(['/home/login'])
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postUpdateUserEmail(fields: any ){
    let params = new HttpParams ()
      // .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyBill', `${this.userInformation.pkyBill}`)

    this.http.post(this._url + `/updateUserEmailNew`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          // this.toastrService.success(`${resp.message}`, '', {timeOut: 5000});
          // this.router.navigate(['/home/login'])
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postForgotPassToSubscribe(fields: any ){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    return this.http.post(this._url + `/logRecoverPassword`, fields, { params } )
  }

  postConfirmForgotPass(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    this.http.post(this._url + `/logChangePassword`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.toastrService.success(`Contraseña restablecida con exito, inicia sesión nuevamente`, '', {timeOut: 5000});
          this.actualStep = 1;
          this.router.navigate(['/home/login'])
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postLogOut(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

      this.http.post(this._url + `/logOutUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          // this.favoriteSelected[0] = false;
          // this.favoriteSelected[1] = 0;
          // localStorage.setItem( 'selectedFavorite', JSON.stringify(this.favoriteSelected));
          this.loginBySocial = false;
          localStorage.removeItem('social');
          this.guestFlag = '1';
          localStorage.setItem('guestFlag', this.guestFlag);
          this.router.navigate(['/home/location']);
          this.searchToggleOff();
          this._auth_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jYXNjYWRhLWNybS50ZXN0XC9hcGlcL2xvZ2luVXNlckNsaWVudCIsImlhdCI6MTY3ODIxMDM5NywiZXhwIjoxNjc4MjEzOTk3LCJuYmYiOjE2NzgyMTAzOTcsImp0aSI6IldXQWRCWjVVUmhMQUFwdUUiLCJzdWIiOjEsInBydiI6ImRjNWM2MmMyODdkOTcyZTE1MjdmOWVkYzQwZDJjMzMxMThjMjljNDQifQ.Sy2bvvod_esOV-ffjElJXy51gHbualazN6SFZtNQllA';
          localStorage.removeItem('LaCascadaToken');
          localStorage.removeItem('LaCascadaUser');
          localStorage.removeItem('LaCascadaFavs');
          localStorage.removeItem('LaCascadaCart');
          localStorage.removeItem('LaCascadaAddresses');
          localStorage.removeItem('LaCascadaFavAdd');
          this._userInformation = {};
          this.postGuestUser();
          this._shoppingCart = {};
          this.favorites = [];
          // this.toastrService.success(`Session cerrada con exito`, '', {timeOut: 5000});
          // window.location.reload();
        }else{
          this.toastrService.error(`${resp.mensaje}`, '', {timeOut: 5000} );
        }
    });
  }

  postRemoveFav(fields: any){

    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);

      return this.http.post(this._url + `/model/InvItemFavourite/delete`, fields, { params } );
  }

  postAddFav(fields: any): any{
    let params = new HttpParams ()
    .set('Authorization', `Bearer ${this._auth_token}`);

    return this.http.post(this._url + `/model/InvItemFavourite/create`, fields, { params } );
  }

  postAddProductShoppingCart(raw: any){
    var newFields = {
      codItem: raw.product.pkyItem,
      fldQuantity: raw.fldQuantity
    }
    var flagCoincidence = false;
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.http.post(this._url + `/registerNewProductCart`, newFields, { headers })
    .subscribe(( resp: any )=>{
      if(resp.status){
          this.getShoppingCart();
          setTimeout(() => {
            this.hideShoppingWidget();
          }, 600);
          this.toastrService.success(`Producto agregado al carrito de compras`, '', {timeOut: 5000});
        if(this.guestFlag == '1'){
          // guest user
          for (let index = 0; index < resp.item.cart.storage_cart_detail.length; index++) {
            if(resp.item.cart.storage_cart_detail[index].codItem == raw.product.pkyItem){
              if(this.shoppingCart.length == 0){
                this._shoppingCart = resp.item.cart;
              }else{
                this.shoppingCart.storage_cart_detail?.forEach(element => {
                  if( element.codItem == raw.product.pkyItem ){
                    if(resp.item.cart.pkyCart == this._shoppingCart.pkyCart){
                      element.fldQuantity = resp.item.cart.storage_cart_detail[index].fldQuantity;
                    }else{
                      element.fldQuantity = element.fldQuantity + resp.item.cart.storage_cart_detail[index].fldQuantity;
                    }

                    flagCoincidence = true;
                  }
                });

                if(flagCoincidence == false){
                  this._shoppingCart.storage_cart_detail.push(resp.item.cart.storage_cart_detail[index]);
                }
              }
            }
          }
          localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));

          this.updateTotalCart$.next(true);

        }
      }else{
        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    },(error: any)=>{
      this.router.navigate(['/home/login']);
      // this.postLogOut({ userId: this._userInformation.pkyBill });
      if(this.guestFlag == '1'){
        this.toastrService.error(`Por favor, recarga la página e intentalo de nuevo`, '', {timeOut: 5000} );
      }else{
        this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
      }
      // if(this.guestFlag == '1'){
      //   this.postGuestUser();
      // }
    });
  }

  postAddProductShoppingCartToSubscribe(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    return this.http.post(this._url + `/registerNewProductCart`, fields, { headers })

  }

  postRemoveProductShoppingCart(fields: any){
    if(this.guestFlag == '0'){
      let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);

      this.http.post(this._url + `/model/SlsCartDetail/delete`, fields, { params })
        .subscribe(( resp: any )=>{
          if(resp.status){
            this.getShoppingCart();

            if(this._shoppingCart.storage_cart_detail.length == 1){
                this._shoppingCart = [];
                this.hideShoppingWidget();
                localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
            }

            this.toastrService.success(`Producto eliminado del carrito de compras`, '', {timeOut: 5000});
          }else{
            this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
          }
      });
    }else{

      if (this._shoppingCart.storage_cart_detail.length == 1) {
        this._shoppingCart = [];
        localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
        window.location.reload();
      }else{
        for (let index = 0; index < this._shoppingCart.storage_cart_detail.length; index++) {
          // console.log(this._shoppingCart.storage_cart_detail[index].codCart);
          if(this._shoppingCart.storage_cart_detail[index].pkyCartDetail == fields.id){
            this._shoppingCart.storage_cart_detail.splice(index, 1);
          }
        }
        this.updateTotalCart$.next(true);
        localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
      }
    }
  }

  postDeletecart(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);

    this.http.post(this._url + `/model/SlsCartDetail/delete`, fields, { params })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this._shoppingCart = [];
          localStorage.removeItem('LaCascadaCart');
          this.getShoppingCart();
          // this.toastrService.success(`Producto eliminado del carrito de compras`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    });
  }

  postUpdateProductShoppingCart(fields: any){

    this.loaderCart.classList.add('show');
    if(this.guestFlag == '0'){
      let headers = new HttpHeaders ({
        Authorization: `Bearer ${this._auth_token}`
      });

      this.http.post(this._url + `/model/SlsCartDetail/edit`, fields, { headers })
        .subscribe(( resp: any )=>{
          if(resp.status){
            // this.toastrService.success(`Carrito actualizado`, '', {timeOut: 5000});
            this.getShoppingCart();
          }else{
            this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
          }
          this.loaderCart.classList.remove('show');
      });
    }else{
      this._shoppingCart.storage_cart_detail.forEach(element => {
        if(element.pkyCartDetail == fields.id){
          element.fldQuantity = fields.fldQuantity;
        }
      });
      localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
      this.loaderCart?.classList.remove('show');
    }
  }

  postAddNewAddress(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.http.post(this._url + `/model/UserAddress/create`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          
          if(fields.favoriteAddress){
            this.favoriteAddress = resp.item.pkyAddress;
            localStorage.setItem('LaCascadaFavAdd', resp.item.pkyAddress);
          }
          this.getAddresses();
          this.toastrService.success(`Nueva dirección agregada`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    }, (error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postAddNewAddressToSubscribe(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    return this.http.post(this._url + `/model/UserAddress/create`, fields, { headers })

  }

  postEdditAddress(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.http.post(this._url + `/model/UserAddress/edit`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if(fields.favoriteAddress){
            this.favoriteAddress = resp.item.pkyAddress;
            localStorage.setItem('LaCascadaFavAdd', resp.item.pkyAddress);
          }else{
            this.favoriteAddress = 0;
            localStorage.removeItem('LaCascadaFavAdd');
          }
          this.getAddresses();
          this.toastrService.success(`Dirección actualizada`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    }, (error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postRemoveAddress(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.http.post(this._url + `/model/UserAddress/delete`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if(fields.favoriteAddress){
            this.favoriteAddress = 0;
            localStorage.removeItem('LaCascadaFavAdd');
          }
          this.getAddresses();
          // if(this._addresses.length == 1){
          //   this._addresses = [];
          //   localStorage.setItem('LaCascadaAddresses', JSON.stringify(this._addresses));
          // }
          this.toastrService.success(`Dirección eliminada`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postApplyCoupon(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    return this.http.post(this._url + `/validateCoupon`, fields, { headers });
  }

  postUpdatePurchase(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.loader.classList.add('show');

    this.http.post(this._url + `/model/SlsCart/edit`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          var newFields = {
            codCart: fields.id,
            codAddress: fields.codAddress,
            fldTaxNumber: fields.fldTaxNumber,
            fldTaxName: fields.fldTaxName,
            // fldPhone: fields.fldPhone,
            codPaymentMethod: fields.codPaymentMethod,
            type : 'web'
          }
          this.postFinishPurchase(newFields);
        }
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
      this.loader.classList.add('show');
    });
  }

  finishPurchase(resp){
    this.lastOrder = resp.item;
    localStorage.setItem('LaCascadaLastOrder', JSON.stringify(this.lastOrder));
    this.toastrService.success(`Pedido realizado`, '', {timeOut: 5000});
    this.router.navigate(['home/order-success']);
    this.getShoppingCart();
  }

  postFinishPurchase(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.http.post(this._url + `/postSales`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if( resp.confirmOrder != undefined && resp.confirmOrder ){
            Swal.fire({
              title: `${resp.message}`,
              icon: 'question',
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonText: 'Aceptar',
              denyButtonText: 'Historial',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                this.loader.classList.add('show');
                fields.confirmOrder = true;
  
                this.postFinishPurchase(fields)
                // this.http.post(this._url + `/postSales`, fields, { headers })
                // .subscribe(( respInner: any )=>{
                //   this.finishPurchase(respInner);
                //   this.loader.classList.remove('show');
                // });
              }
              if (result.isDenied) {
                this.router.navigate(['/home/order-history']);
              }
            })
          }else{
            this.finishPurchase(resp);
          }
          
          this.loader.classList.remove('show');
        }else{
          this.loader.classList.remove('show');
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    },(error: any) =>{
      this.loader.classList.remove('show');
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postGuestUser(){
    var href: any = window.location.href.split('/');
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    if(href[href.length - 2] != 'login-web'){
      this.http.post(this._url + `/loginUserGuest`, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this._auth_token = resp.token;
          localStorage.setItem('LaCascadaToken', resp.token);
          localStorage.setItem('LaCascadaUser', JSON.stringify(resp.item.user));
          this._userInformation = resp.item.user;
        }

      },(error: any) =>{
        this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
      });
    }
  }

  postSendMail(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.http.post(this._url + `/helpMessage`, fields , { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.toastrService.success(`Mensaje enviado con exito, te responderemos tan pronto como nos sea posible`, '', {timeOut: 5000});
        }

    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postSendMailToSubscribe(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    return this.http.post(this._url + `/helpMessage`, fields , { headers })
  }
}
